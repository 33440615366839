<template>
    <CTabs tabs class="nav-underline nav-underline-primary">
         <CTab active>
           <template slot="title">
             <CIcon name="cil-task" />&nbsp;Filtre
           </template>
           <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="ID"
                  placeholder="ID"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.inventoryLogId"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Stok Alanı"
                  placeholder="Stok alanı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.posName"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="SKU Ürün Adı"
                  placeholder="Ürün adı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.productName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="SKU Adı"
                  placeholder="SKU adı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.skuName"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="SKU Kodu"
                  placeholder="SKU kodu"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.skuCode"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="SKU Barkod"
                  placeholder="SKU barkod"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.skuBarcode"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Adet"
                  placeholder="Adet"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.inventoryLogAmount"
                />
              </CCol>
            </CRow>
           </CForm>
         </CTab>
     </CTabs>
 </template>
 
 <script>
    export default{
        name: "InventoryLogFilter",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            filter: Function
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
             clearFilters: function(){
                 this.data = {}
                 this.filter()
             }
        }
    }
 </script>